<template>
  <div class="select__list">
    <perfect-scrollbar class="select__scroll">
      <div
        class="select__item"
        :class="{
          'select__item--single': !multiple,
          'select__item--checked': isChecked(o),
        }"
        :key="i"
        @click="multiple ? false : $emit('select', o)"
        v-for="(o, i) in options"
      >
        <CheckComponent @change="$emit('select', o)" :checked="isChecked(o)" v-if="multiple">
          <span>{{ getName(o) }}</span>
        </CheckComponent>
        <template v-else>{{ getName(o) }}</template>
      </div>
      <div class="select__item select__item--single" v-if="!options.length">Ничего не найдено</div>
    </perfect-scrollbar>
    <a href="#" class="select__clean-list" @click.prevent="$emit('clean')" v-if="multiple && clearable">
      Очистить
    </a>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CheckComponent from "../CheckComponent";

export default {
  name: "SelectListComponent",
  props: {
    labelName: { type: String, default: "name" },
    selected: { type: [Array, Number, Object] },
    options: { type: Array, required: true },
    multiple: Boolean,
    clearable: Boolean,
  },
  components: {
    CheckComponent,
    PerfectScrollbar,
  },
  methods: {
    isChecked(option) {
      if (this.multiple && this.selected && this.selected instanceof Array) {
        return this.selected.map((s) => JSON.stringify(s)).includes(JSON.stringify(option));
      }
      if (!this.multiple) {
        return JSON.stringify(option) === JSON.stringify(this.selected);
      }
      return false;
    },
    getName(option) {
      if (this.labelName) {
        return option[this.labelName];
      }
      return option;
    },
  },
};
</script>

<style lang="stylus">
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"
.select__list {
  absolute bottom left
  width calc(100% + 4px)
  transform translateY(calc(100% - 2px))
  z-index 10
  background var(--main_white)
  border 2px solid var(--input_focused_border)
  border-radius 0 0 var(--radius) var(--radius)
  box-sizing: border-box;
  margin -2px
  box-shadow: var(--select_input_shadow);
  overflow hidden
  max-height: 200px;
  display grid
  grid-template-rows 1fr auto
}

.select__scroll {
  display grid
  grid-auto-rows 40px
}

.select__clean-list {
  border-top 2px solid var(--input_focused_border)
  padding 8px 12px
  color var(--main_dark)
  cursor pointer
  text-decoration none

  &:hover {
    color var(--main_dark)
    border-color var(--input_focused_border)
  }
}

.select__item {
  width 100%
  text-align left
  cursor pointer
  transition .3s
  outline none
  color var(--main_dark)
  text-decoration none

  &--single {
    padding 8px 12px
  }

  .check {
    padding 8px 12px
  }

  &:focus
  &--checked
  &:hover {
    background var(--input_focused_border)
    color var(--main_dark)
  }
}
</style>

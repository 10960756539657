var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blurInput),expression:"blurInput"}],staticClass:"select",class:{
    'select--input': _vm.inputState === _vm.inputStates.FOCUS,
    'select--list': _vm.dropdownState === _vm.dropdownStates.BOTTOM,
    'select--multiple': _vm.multiple,
    'select--loading': _vm.loading,
    'select--error': _vm.error,
    'select--dark': _vm.dark,
    'select--list-top': _vm.dropdownState === _vm.dropdownStates.TOP,
    'select--no-title': !_vm.title,
  }},[(_vm.multiple && _vm.selectedOptions && _vm.selectedOptions.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"select__selected",class:{
      'select__selected--one': _vm.selectedOptions.length === 1,
    }},[_c('a',{staticClass:"select__selected-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.select(_vm.selectedOptions[0])}}},[_c('span',[_vm._v(_vm._s(_vm.cutString(_vm.getName(_vm.selectedOptions[0]), 12)))]),_c('CloseIcon')],1),(_vm.selectedOptions.length > 1)?_c('a',{staticClass:"select__selected-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.focusInput.apply(null, arguments)}}},[_c('span',[_vm._v("...")])]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"select__field-container"},[(_vm.title)?_c('span',{staticClass:"select__title",on:{"click":_vm.focusInput}},[_vm._v(" "+_vm._s(_vm.computedTitle)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"input",staticClass:"select__field",domProps:{"value":(_vm.search)},on:{"focus":_vm.focusInput,"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),(!_vm.multiple && !_vm.searchEmpty && _vm.clearable)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"select__clear",attrs:{"type":"button"},on:{"click":_vm.clean}},[_c('CloseIcon')],1):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"select__action",attrs:{"type":"button","tabindex":"-1"},on:{"click":_vm.toggle}},[_c('ChevronDownIcon')],1),(_vm.dropdownState !== _vm.dropdownStates.CLOSED)?_c('SelectListComponent',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"list",attrs:{"options":_vm.filteredOptions,"selected":_vm.selectedOptions ? _vm.selectedOptions : _vm.value,"multiple":_vm.multiple,"labelName":_vm.labelName,"clearable":_vm.clearable},on:{"select":_vm.select,"clean":_vm.clean}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }